
<script setup>
import { useNewsPaperStore } from '@/stores';
import { useDraftStore } from '@/stores';
import { useFilemountStore } from '@/stores';
import { useBackendStore } from '@/stores';
import { useAuthStore } from '@/stores';
import { ref, computed, onMounted, watch, toRefs } from 'vue';
import CategoryTree from './CategoryTree.vue';
import FolderTree from './FolderTree.vue';
import NewspaperPublish from '@/components/newspaper-publish';
import Lazy from '../Lazy.vue';
import { useNotification } from '@kyvg/vue3-notification';
import { useLoading } from '@/loader';
import { ClassicEditor } from '@ckeditor/ckeditor5-editor-classic';
import disabledConfig from '@/ckeditor5/config/disabled';
import { useI18n } from 'vue-i18n';

const newsPaperStore = useNewsPaperStore();
const draftStore = useDraftStore();
const backendStore = useBackendStore();
const filemountStore = useFilemountStore();
const authStore = useAuthStore();
const loader = useLoading();
const { notify }  = useNotification();
const { t } = useI18n({});

const props = defineProps({
    "show": {
        "type": Boolean,
        "default": false
    },
    "checkSelected": {
        "type": Function
    }
});

const emit = defineEmits(['modal-action']);

// BEGIN: CKEditor5
const editor = ClassicEditor;
let editorConfig = disabledConfig;
editorConfig['dam-image']['click'] = null;
// END: CKEditor5

const selectedNewspapers = ref([]);
const activeTab = ref("start");
const selectedFolder = ref(null);
const authors = ref({});
const folderName = ref("");
const published_report = ref([]);

const { show } = toRefs(props);

const modal = ref(null);
const modalPublish = ref(null);
const modalloader = ref(null);


const selectedBackends = computed(() => {
    let backends = [];
    selectedNewspapers.value.forEach(newspaper_id => {
        let backend_id = newsPaperStore.getNewspaperById(newspaper_id).backendId;
        //let backend_id = newspaper_id.split("-")[0];
        if(backends.indexOf("backend-" + backend_id) == -1) {
            backends.push("backend-" + backend_id);
        }
    });
    return backends;
});

const getBackendName = (backend) => {
    let backend_id = backend.split("-")[1];
    return backendStore.backend[backend_id].name;
}

const toStart = () => {
    activeTab.value = 'start';
}


const setAuthors = (newspaper_id) => {
    authors.value[newspaper_id] = newsPaperStore.getAuthors(newspaper_id);
}

const getPredefinedAuthor = (newspaper) => {
    const parts = newspaper.split("-");
    let author_id = null;
    try {
        author_id = authStore.user.preferences.authors[parts[0]][parts[1]];
        if(author_id) {
            draftStore.publishing[newspaper].news.tx_ttnewsauthor_render = author_id;
        }
    } catch(e) {
        // No predefined author set
    }
    return author_id;
}

const uploadBackends = computed( () => {
    if(selectedNewspapers.length == 0) {
        return [];
    }

    let imgSources = {};
    const parser = new DOMParser();
    const htmlDoc = parser.parseFromString(draftStore.draftDetail.bodytext, 'text/html');
    const inlineImgs = htmlDoc.getElementsByTagName('img');
    inlineImgs.forEach((img, index) => {
        try {
            const url = new URL(img.src);
            if(url.pathname.startsWith("/draft-images/")) {
                imgSources['from-pc'] = true;
            } else {
                try {
                    const [backendId, damUid] = url.searchParams.get('uid').split("-")
                    if(backendId) {
                        imgSources[backendId] = true;
                    }
                } catch(e) {
                    console.log("IMG src", img.src);
                    imgSources['from-pc'] = true;
                }
            }
        } catch(e) {
            console.log(e);
        }
    });
    draftStore.draftDetailImages.images.forEach((image, index) => {
        try {
            const [backendId, damUid] = image.remote_id.split("-");
            if(backendId) {
                imgSources[backendId] = true;
            } else {
                imgSources['from-pc'] = true;
            }
        } catch(e) {
            imgSources['from-pc'] = true;
        }
    });
    draftStore.draftDetailFiles.files.forEach((file, index) => {
        try {
            const [backendId, damUid] = file.remote_id.split("-");
            if(backendId) {
                imgSources[backendId] = true;
            } else {
                imgSources['from-pc'] = true;
            }
        } catch(e) {
            imgSources['from-pc'] = true;
        }
    });
    let backends = [];
    selectedNewspapers.value.forEach(newspaper_id => {
        let backend_id = newsPaperStore.getNewspaperById(newspaper_id).backendId;
        //let backend_id = newspaper_id.split("-")[0];
        if(imgSources['from-pc']){
            if(backends.indexOf("backend-" + backend_id) == -1) {
                backends.push("backend-" + backend_id);
            }
        } else {
            const filtered = Object.keys(imgSources).filter((key, index) => {
                if(key == 'from-pc') return false;
                if(key == backend_id) return false;
                return true;
            });
            if(filtered.length && backends.indexOf("backend-" + backend_id) == -1) {
                backends.push("backend-" + backend_id);
            }
        }
    });
    return backends;
});

const folderSelect = (path) => {    
    let backend_id = activeTab.value.split("-")[1];
    draftStore.publishUpload[backend_id] = path;
    selectedFolder.value = path;
}

const isFolderSelected = (path) => {
    return draftStore.publishUpload[activeTab.value.split("-")[1]] == path;
}

const currentFilemount = (backend_id) => {
    let backend_uid = backend_id.split("-")[1];
    return filemountStore.filemount[backend_uid];
}

const checkCategoryAndPublish = () => {
    if(checkCategorySelected()) {
        publish();
    }
}

const publish = async () => {
    loader.show({container: modalloader.value });
    try {
        let resp = await draftStore.publish();
        selectedNewspapers.value = [];
        for(const [newspaper_id, type] of Object.entries(newsTypeSelected.value)) {
            newsTypeSelected.value[newspaper_id] = null;
        }
        draftStore.publishingCats = ref([]);
        draftStore.initPublish();
        loader.hide();
        published_report.value = resp;
        if(Number(authStore.user.preferences.showURLAfterPublishing) > 0) {
            activeTab.value = 'report';
        } else {
            done();
        }
        
    } catch(error) {
        loader.hide();
        notify({
            text: "Errore di pubblicazione",
            type: 'danger'
        });
    }
}

const validateSelectedNewspapers = () => {
    if(selectedNewspapers.value.length < 1) {
        notify({
            title: 'Errore',
            text: t('select-almost-one-newspapaper'),
            type: 'danger'
        });
        return false;
    }
    // Check external news
    let publishNewsPaperType = [];
    selectedNewspapers.value.forEach( (newspaper_id) => {
        publishNewsPaperType.push({
            newspaper_id: newspaper_id,
            type: newsTypeSelected.value[newspaper_id]
        });
    });
    const linked = publishNewsPaperType.filter( (item) => {
        return item.type == 'linked';
    });
    if(linked.length > 0) {
        const master = publishNewsPaperType.filter ( (item) => {
            return item.type == 'master';
        });
        if(master.length !== 1) {
            notify({
                title: 'Errore',
                text: t('wrong-linked-news'),
                type: 'danger'
            });
            return false;
        }
    }
    console.log("publishNewsPaperType", publishNewsPaperType);
    return true;
}

const next = async () => {
    if(!validateSelectedNewspapers()) {
        return;
    }

    let nextTab = selectedNewspapers.value[0];

    loader.show({container: modalloader.value });
    try {
        setAuthors(nextTab);
    } catch(e) {
        notify({
            title: 'Errore',
            text: 'Impossibile caricare il prossimo step. Errore: ' + e,
            type: 'danger'
        });
        loader.hide();
        return;
    }

    draftStore.publishing = {};

    selectedNewspapers.value.forEach((newspaper) => {
        draftStore.publishing[newspaper] = new Object({
            id: 0,
            categories: [],
            news: JSON.parse(JSON.stringify(draftStore.draftDetail)),
            openedTree: [],
            type: newsTypeSelected.value[newspaper] ?? "normal"
        });
        draftStore.publishing[newspaper].news.image = JSON.parse(JSON.stringify(draftStore.draftDetailImages.images));
        draftStore.publishing[newspaper].news.file = JSON.parse(JSON.stringify(draftStore.draftDetailFiles.files));
        // Set predefined author only if field "author" is empty
        if(draftStore.publishing[newspaper].news.author.trim() == "") {
            draftStore.publishing[newspaper].news.tx_ttnewsauthor_render = getPredefinedAuthor(newspaper);
        } else {
            draftStore.publishing[newspaper].news.tx_ttnewsauthor_render = 0;
        }
        draftStore.publishing[newspaper]['news']['category'] = [];
    });
    loader.hide();
    activeTab.value = nextTab;
}


const selectUploadFolder = () => {
    if(checkCategorySelected()) {
        activeTab.value = uploadBackends[0];
        selectedFolder.value = null;
    }
}

const checkCategorySelected = () => {
    if(draftStore.publishing[activeTab.value].news.category.length == 0) {
        notify({
            title: 'Error',
            text: t('select-almost-one-category'),
            type: 'danger'
        });
        return false;
    }
    return true;
}

const isBackendTab = () => {
    return activeTab.value.split('-')[0] == 'backend';
}

const isNewspaperTab = () => {
    return activeTab.value.split('-')[0] != 'backend';
}

const isFirstBackend = () => {
    return isBackendTab() && activeTab.value == selectedBackends[0];
}

const isFirstUploadBackend = () => {
    return isBackendTab() && activeTab.value == uploadBackends[0];
}

const isLastBackend = () => {
    return isBackendTab() && activeTab.value == selectedBackends[selectedBackends.length - 1];
}

const isLastUploadBackend = () => {
    return isBackendTab() && activeTab.value == uploadBackends[uploadBackends.length - 1];
}

const lastNewspaper = () => {
    activeTab.value = selectedNewspapers.value[selectedNewspapers.value.length - 1];
    setAuthors(activeTab.value);
}

const prevNewspaper = () => {
    loader.show({container: modalloader.value });
    try {
        activeTab.value = selectedNewspapers.value[selectedNewspapers.value.indexOf(activeTab.value) - 1];
        setAuthors(activeTab.value);
    } catch(e) {
        notify({
            title: 'Errore',
            text: 'Impossibile caricare il giornale. Errore: ' + e,
            type: 'danger'
        });
    }
    loader.hide();
}

const nextNewspaper = async () => {
    if(draftStore.publishing[activeTab.value].news.category.length == 0) {
        notify({
            title: 'Errore',
            text: 'Devi selezionare almeno una categoria',
            type: 'danger'
        });
        return;
    }
    loader.show({container: modalloader.value });
    
    try {
        activeTab.value = selectedNewspapers.value[selectedNewspapers.value.indexOf(activeTab.value) + 1];
        setAuthors(activeTab.value);
        draftStore.publishing[activeTab.value].news.tx_ttnewsauthor_render = ref(authors.value[activeTab.value][0]);
    } catch(e) {
        notify({
            title: 'Errore',
            text: 'Impossibile caricare il giornale. Errore: ' + e,
            type: 'danger'
        });
    }
    loader.hide();
}

const nextBackend = () => {
    if(selectedFolder.value == null) {
        notify({
            title: 'Errore',
            text: 'Devi selezionare una cartella di upload',
            type: 'danger'
        });
        return;
    }
    selectedFolder.value = null;
    activeTab.value = selectedBackends[selectedBackends.indexOf(activeTab.value) + 1];
}

const prevBackend = () => {
    selectedFolder.value = null;
    activeTab.value = selectedBackends[selectedBackends.indexOf(activeTab.value) - 1];
}

const isFirstNewspaper = () => {
    return isNewspaperTab() && activeTab.value == selectedNewspapers.value[0];
}

const isLastNewspaper = () => {
    return isNewspaperTab() && activeTab.value == selectedNewspapers.value[selectedNewspapers.value.length - 1];
}

const cancel = () => {
    close();
}

const close = () => {
    activeTab.value = "start";
    emit('modal-action', 'close');
}

const done = () => {
    activeTab.value = "start";
    emit('modal-action', 'done');
}

const catSelect = (cat_id) => {
    let cat_only_id = cat_id.split("-")[2];
    if(draftStore.publishing[activeTab.value].news.category.indexOf(cat_only_id) === -1) {
        draftStore.publishing[activeTab.value].news.category.push(cat_only_id)
    } else {
        draftStore.publishing[activeTab.value].news.category.splice(draftStore.publishing[activeTab.value].news.category.indexOf(cat_only_id), 1);
    }
}

watch( activeTab, (value) => {
    modal.value.scrollTo({ top: 0, behavior: "smooth" });
});

watch( show, (value) => {
    if (value) {
        activeTab.value = 'start';
        modalPublish.value.show();
    }
    else {
        modalPublish.value.hide();
    }
});

const backendSelected = ref(0);
function selectBackend(backend_id) {
  backendSelected.value = backend_id;
}
const backendClass = computed( () => {
  return (backend_id) => {
    if(backendSelected.value == backend_id) {
      return 'menu-open';
    } else {
      return '';
    }
  }
});

onMounted( () => {
    modalPublish.value = new bootstrap.Modal(modal.value);
    modalPublish.value._config.backdrop = "static";
    modalPublish.value._config.keyboard = false;
    $(modalPublish.value._element).on("hide.bs.modal", cancel);
});




filemountStore.fetchFilemount(authStore.user.preferences.hideArchiveTree);

const preferredNewspapers = computed( () => {
  let newspapers = [];
  if(authStore.user?.preferences?.newspapers?.length > 0 && newsPaperStore.newspaper && Object.keys(newsPaperStore.newspaper).length > 0) {
    const filtered = authStore.user.preferences.newspapers.filter( (newspaper_id) => {
      let parts = newspaper_id.split('-');
      if(parts[0] in newsPaperStore.newspaper) {
        for(const [name, newspaper] of Object.entries(newsPaperStore.newspaper[parts[0]])) {
          if(newspaper.uid == parts[1]) {
            return true;
          }
        }
      }
      return false;
    });
    newspapers = filtered;
  }

  return newspapers;
});

const newsTypeOptions = [{
    id: 'linked',
    label: 'Linkata'
}, {
    id: 'master',
    label: 'Principale'
}];

const newsTypeSelected = ref({});

</script>

<template>
    <div class="modal fade" ref="modal"  tabindex="-1" role="dialog" data-backdrop="static" data-keyboard="false" >
        <div class="modal-dialog modal-xl" role="document" ref="modalloader">
            <div class="modal-content">
            <div class="modal-body">
                <div v-if="show" class="container h-100">
                    <div class="tab-content" id="myTabContent">
                        <div :class="(activeTab == 'start' ? 'tab-pane fade active show' : 'tab-pane fade')" id="home" role="tabpanel" aria-labelledby="home-tab">
                            <NewspaperPublish v-model="selectedNewspapers" @update:newsTypeSelected="(value) => newsTypeSelected = value"></NewspaperPublish>
                            <div class="row justify-content-end gx-5">
                                <div class="col-auto">
                                    <button class="btn btn-secondary" @click="cancel()">Annulla</button>
                                </div>
                                <div class="col-auto">
                                    <button class="btn btn-primary" @click="next()">Avanti</button>
                                </div>
                            </div>
                        </div>
                        <div v-if="selectedNewspapers.length > 0" v-for="newspaper in selectedNewspapers" :class="(activeTab == newspaper ? 'tab-pane fade active show' : 'tab-pane fade')" :id="newspaper" role="tabpanel" ref="newspaper">
                            <template v-if="activeTab == newspaper && draftStore.publishing[newspaper]">
                                <h2>Configura <mark>{{ newsPaperStore.getNewspaperById(newspaper).name }}</mark></h2>
                                <div class="row mt-3">
                                    <div class="col-lg-3">
                                        <CategoryTree :openedTree="draftStore.publishing[newspaper].openedTree" :publish=ref(true) :categories="newsPaperStore.getNewspaperById(newspaper).categories" :newspaper_id="newspaper" :level=1 :opened=true @catselect="catSelect" />
                                    </div>
                                    <div class="col-lg-9">
                                        <div class="form-group">
                                            <label for="title">Titolo</label>
                                            <input type="text" class="form-control" id="title" v-model="draftStore.publishing[newspaper].news.title">
                                        </div>
                                        <div class="form-group">
                                            <label for="short">Sottotitolo</label>
                                            <!--<input type="text" class="form-control" id="short" v-model="draftStore.publishing[newspaper].news.short">-->
                                            <textarea class="form-control" rows="2" id="short" v-model="draftStore.publishing[newspaper].news.short"></textarea>
                                        </div>
                                        <!--
                                        <div class="form-group">
                                            <label for="bodytext">Testo</label>
                                            <ckeditor disabled ref="editor" :editor="editor" v-model="draftStore.publishing[newspaper].news.bodytext" :config="editorConfig"></ckeditor>
                                        </div>
                                        -->
                                        <div class="form-group">
                                            <label for="short">Parole chiave</label>
                                            <input type="text" class="form-control" id="keywords" v-model="draftStore.publishing[newspaper].news.keywords">
                                        </div>
                                        <div class="form-group">
                                            <label for="author">Autore</label>
                                            <v-select v-model="draftStore.publishing[newspaper].news.tx_ttnewsauthor_render" :options="authors[newspaper]" label="label" :reduce="(el) => el.id" ></v-select>
                                            <input type="text" class="form-control" id="author" v-model="draftStore.publishing[newspaper].news.author">
                                        </div>
                                        <div class="form-group">
                                            <label for="author">Posizione</label>
                                            <div class="box-positions">
                                                <div v-for="(position, index) in newsPaperStore.positions" :key="position.id" class="clearfix">
                                                    <div class="icheck-primary d-inline">
                                                        <input type="checkbox" class="form-check-input" :true-value="[]" :id="`position-${position.id}`" v-model="draftStore.publishing[newspaper].news.positions" :value="position.id">
                                                        <label class="form-check-label" :for="`position-${position.id}`">{{position.name}}</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </template>
                            <div class="row justify-content-end gx-5">
                                <div class="col-auto">
                                    <button class="btn btn-secondary" @click="cancel()">Annulla</button>
                                </div>
                                <div class="col-auto" v-if="isFirstNewspaper()">
                                    <button class="btn btn-primary" @click="toStart()">Indietro</button>
                                </div>
                                <div class="col-auto" v-else-if="isNewspaperTab()">
                                    <button class="btn btn-primary" @click="prevNewspaper()">Indietro</button>
                                </div>
                                <div class="col-auto" v-if="isLastNewspaper()">
                                    <!-- button v-if="uploadBackends.length > 0" class="btn btn-success" @click="selectUploadFolder()">Ora seleziona la cartella di upload</button -->
                                    <button class="btn btn-success" @click="checkCategoryAndPublish()">Pubblica</button>
                                </div>
                                <div v-else-if="isNewspaperTab()" class="col-auto">
                                    <button class="btn btn-primary" @click="nextNewspaper()">Avanti</button>
                                </div>
                            </div>
                        </div>
                        <div v-for="backend in uploadBackends" :class="( (activeTab == backend) ? 'tab-pane fade active show' : 'tab-pane fade')" :id="backend" role="tabpane" ref="backend">
                            <h2>Cartella di upload su <mark>{{ getBackendName(backend) }}</mark></h2>
                            <p>Seleziona la cartella nella quale verrano caricate le immagini e gli allegati dell'articolo sul server.</p>
                            <!-- TODO BEGIN-->
                            <div class="form-group">
                                <div class="row mt-3">
                                    <div class="col-lg-2 col-12">
                                        <label for="short">Nuova cartella</label>
                                    </div>
                                    <div class="col-lg-4 col-8 ">
                                        <input v-model="folderName" type="text" class="form-control" />
                                    </div>
                                    <div class="col-lg-6 col-4">
                                        <button class="btn btn-primary" @click="newFolder">Crea</button>
                                    </div>
                                </div>
                            </div>
        
                                <ul class="filemount-list">
                                    <li :class="(isFolderSelected(filemount.id) ? 'filemount-item selected' : 'filemount-item')" v-for="filemount in currentFilemount(backend)">
                                        <span class="filemount" @click="folderSelect(filemount.id)">{{ filemount.name }}</span>
                                        <Lazy><FolderTree :checkSelected="isFolderSelected"  :filemount_id="filemount.id" :baseUrl="filemount.base_url" :parentPath="filemount.id" :folders="filemount.root" :level=1 :opened=(!authStore.user.preferences.hideArchiveTree) @folderselect="folderSelect" /></Lazy>
                                    </li>
                                </ul>
                                <div class="row justify-content-end gx-5">
                                    <div class="col-auto mb-1">
                                        <button class="btn btn-secondary" @click="cancel()">Annulla</button>
                                    </div>
                                    <div class="col-auto mb-1" v-if="isFirstUploadBackend()">
                                        <button class="btn btn-primary" @click="lastNewspaper()">Indietro</button>
                                    </div>
                                    <div class="col-auto mb-1" v-else-if="isBackendTab()">
                                        <button class="btn btn-primary" @click="prevBackend()">Indietro</button>
                                    </div>
                                    <div class="col-auto mb-1" v-if="isLastUploadBackend()">
                                        <button class="btn btn-success" @click="publish()">Pubblica</button>
                                    </div>
                                    <div class="col-auto mb-1" v-else-if="isBackendTab()">
                                        <button class="btn btn-primary" @click="nextBackend()">Avanti</button>
                                    </div>
                                </div>
                            
                            <!-- TODO END -->
                        </div>
                        <div :class="(activeTab == 'report' ? 'tab-pane fade active show' : 'tab-pane fade')" id="report" role="tabpanel" aria-labelledby="report-tab">
                            <h3 class="mb-4">Elenco pubblicazioni</h3>
                            <div class="published-list">
                                <template v-for="(report, index) in published_report.data" :key="index">                                    
                                    <p v-if="index != 'errors'" :class="(report.error ? 'error' : 'success')">
                                        <strong>{{ newsPaperStore.getNewspaperById(index).name }}: </strong>
                                        <template v-if="report.error">Codice errore {{ report.error}}</template>
                                        <template v-else>{{ report.data.link }}</template>
                                        <hr />
                                    </p>
                                </template>
                            </div>
                            <div class="row justify-content-end gx-5">
                                <div class="col-auto">
                                    <button class="btn btn-secondary" @click="done()">Chiudi</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </div>
    </div>
</template>

