<template>
<section class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-6">
        <h1>Bozze</h1>
      </div>
      <div class="col-sm-6">
        <ol class="breadcrumb float-sm-right">
          <li class="breadcrumb-item"><router-link to="/">Home</router-link></li>
          <li class="breadcrumb-item active">Bozze</li>
        </ol>
      </div>
    </div>
  </div>
</section>


<section class="content">
    <div class="container-fluid">
        <div class="card card-default">
            <form name="draft-edit">
              <div class="card-body">

                <div v-if="availableLang().length > 1" class="form-group">
                    <label for="lang">Lingua</label>
                    <v-select :modelValue="draftStore.draftDetail.language_id" :options="availableLang()" @option:selected="(option) => onLangSelected(option)" label="title" :reduce="(el) => el.uid" :clearable="false" >
                        <template #option="{title, suffix}">
                            {{ title }}{{ suffix }}
                        </template>
                        <template #selected-option="{title, suffix}">
                            {{ title }}{{ suffix }}
                        </template>
                    </v-select>
                </div>
                <div class="form-group">
                    <label class="control-label" for="title">Titolo</label>
                    <input required type="text" class="form-control" id="title" v-model="draftDetail.title" @keydown.enter="(e) => e.preventDefault()">
                </div>
                <div class="form-group">
                    <label for="short">Sottotitolo</label>
                    <!--<input type="text" class="form-control" id="short" v-model="draftDetail.short" @keydown.enter="(e) => e.preventDefault()">-->
                    <textarea class="form-control" rows="2" id="short" v-model="draftDetail.short" @keydown.enter="(e) => e.preventDefault()"></textarea>
                </div>
                <div class="form-group">
                    <label>Testo</label>
                    <div id="editor-container">
                        <ckeditor ref="draftEditor" :editor="editor" v-model="editorData" :config="draftEditorConfig" @ready="onEditorReady()"></ckeditor>
                    </div>
                </div>
                <div class="form-group">
                    <label for="short">Parole chiave <small>(separate da ,)</small></label>
                    <input type="text" class="form-control" id="keywords" v-model="draftDetail.keywords" @keydown.enter="(e) => e.preventDefault()">
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="title">Data</label>
                            <datepicker required v-model="draftDetail.datetime" :text-input="textInputOptions" :disabled="!enabledDate" id="datetime" locale="it" format="dd/MM/yyyy HH:mm" auto-apply :close-on-auto-apply="false" partial-flow :flow="['calendar', 'time']" />
                        </div>
                    </div>
                    
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="author">Autore</label>
                            <input type="text" class="form-control" v-model="draftDetail.author" id="author" @keydown.enter="(e) => e.preventDefault()" />
                        </div>
                    </div>
                    
                </div>
                
                <div class="row">
                    <div class="col-md-6">
                    <div class="form-group">
                        <label for="title">Data inizio</label>
                        <!-- input type="text" class="form-control" id="starttime" v-model="draftDetail.starttime" -->
                        <datepicker v-model="draftDetail.starttime" :inputFormat="dateFormat" :text-input="textInputOptions"  locale="it" format="dd/MM/yyyy HH:mm" auto-apply :close-on-auto-apply="false" partial-flow :flow="['calendar', 'time']"  @update:model-value="onStartTimeChange" />
                    </div>
                    </div>
                    <div class="col-md-6">
                    <div class="form-group">
                        <label for="title">Data fine</label>
                        <!-- input type="text" class="form-control" id="endtime" v-model="draftDetail.endtime" -->
                        <datepicker v-model="draftDetail.endtime" :inputFormat="dateFormat" :text-input="textInputOptions"  locale="it" format="dd/MM/yyyy HH:mm" auto-apply :close-on-auto-apply="false" partial-flow :flow="['calendar', 'time']"  />
                    </div>
                    </div>
                </div>
                
                <div class="row">
                    <div class="col-sm-6">
                        <label>Posizione</label>
                        <div class="form-group">
                        <div v-for="(position, index) in newsPaperStore.positions" :key="position.id">
                            <div class="icheck-primary d-inline">
                                <input type="checkbox" :id="position.id" :true-value="[]" v-model="draftDetail.positions" :value="position.id">
                                <label :for="position.id">{{position.name}}</label>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <label></label>
                        <div class="form-group clearfix">
                            <div  class="icheck-primary d-inline">
                                <input type="checkbox" id="premium" true-value="1" false-value="0" v-model="draftDetail.premium">
                                <label for="premium">PREMIUM News</label>
                            </div>
                        </div>

                        <div class="form-group clearfix">
                            <div  class="icheck-primary d-inline">
                                <input type="checkbox"  id="hidden" true-value="1" false-value="0" v-model="draftDetail.hidden">
                                <label for="hidden">Nascondi</label>
                            </div>
                        </div>
                    </div>
                </div>
                
                
                <div class="row">
                    <div class="col-md-6">
                    <div class="form-group">
                        <label for="title">Data inizio boxatura</label>
                        <datepicker v-model="draftDetail.boxed_from" :inputFormat="dateFormat" :text-input="textInputOptions"  locale="it" format="dd/MM/yyyy HH:mm" auto-apply :close-on-auto-apply="false" partial-flow :flow="['calendar', 'time']" />
                    </div>
                    </div>
                    <div class="col-md-6">
                    <div class="form-group">
                        <label for="title">Data fine boxatura</label>
                        <datepicker v-model="draftDetail.boxed_to" :inputFormat="dateFormat" :text-input="textInputOptions" locale="it" format="dd/MM/yyyy HH:mm" auto-apply :close-on-auto-apply="false" partial-flow :flow="['calendar', 'time']"  />
                    </div>
                    </div>
                </div>
                
                <div class="row">
                    <div class="col-md-6">
                        <label>Immagini</label>
                        <dam-images :store="draftStore" :media_file_path="'/draft-images'" @ondambrowse="onDamBrowse(true, 'attach')" @ondamedit="(media) => openEditor(media, 'attachment')" />
                        
                    </div>
                    <div class="col-md-6">
                        <label>Allegati</label>
                        <dam-files :store="draftStore" :media_file_path="'/draft-files'" @ondambrowse="onDamBrowse(true, 'file')" />
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 ">
                        <label>Notizie correlate</label>
                        <RelatedDraft  @onrelatedbrowse="onRelatedBrowse(true)" v-model="draftDetail.related" />
                    </div>
                </div>

              </div>
                
              <div class="card-footer">
                <div class="row">
                  <div class="col-sm-12 col-md-5 mt-1 mb-1" v-if="authStore.user.preferences.autoSaveDraft > 0" >Salvataggio automatico tra <b>{{ autoSaveDelta }}</b></div>
                  <div class="col-sm-12 col-md-7">
                    <div class="float-right">
                      <div class="btn btn-sm btn-secondary" @click="closeDraft()">Chiudi</div>
                      <div class="btn btn-sm btn-secondary ml-1" @click="saveDraft()">Salva</div>
                      <div class="btn btn-sm btn-secondary ml-1" @click="saveDraftAndClose()">Salva e chiudi</div>
                      <div class="btn btn-sm btn-primary ml-1" @click="publishDraft()">Pubblica</div>
                    </div>
                  </div>
                </div>
              </div>

            </form>
        </div>
    </div>
</section>

<div class="modal fade" ref="damEditor"  tabindex="-1" role="dialog" data-keyboard="false" >
    <div class="modal-dialog modal-fullscreen" role="document">
        <div class="modal-content">
            <!--div class="modal-header">
                <h4 class="modal-title">Modifica immagine</h4>
            </div -->
            <div class="modal-body" style="background-color: black;">
                <cropper v-if="imgToEdit" :src="imgToEdit" :default-size="defaultSize"  ref="imageEditor" default-boundaries="fit" :auto-zoom="false" image-restriction="fit-area" />
                <div class="image-editor-controls">
                    <button type="button" @click="rotate(-90)"><i class="fas fa-rotate-left"></i></button>
                    <button type="button" @click="rotate(90)"><i class="fas fa-rotate-right"></i></button>
                    <button type="button" @click="flip(true, false)"><i class="fas fa-arrows-left-right"></i></button>
                    <button type="button" @click="flip(false, true)"><i class="fas fa-arrows-up-down"></i></button>
                    <button type="button" @click="saveImage()"><i class="fas fa-save"></i></button>
                    <button type="button" @click="closeImageEditor()"><i class="fas fa-close"></i></button>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" ref="damBrowser"  tabindex="-1" role="dialog" data-keyboard="false" >
    <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
            <div class="modal-header">
                 <h4 class="modal-title">Media</h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
                </button>
            </div>
    
            <div class="modal-body">
                <DamImageBrowser ref="modal_browser" :show="showDamBrowser" :store="draftStore" @addimage="onAddImage" @hidebsmodal="onDamClose" :context="damContext" />
            </div>
        </div>
    </div>
</div>

<div class="modal fade" ref="relatedBrowser" tabindex="-1" role="dialog" data-keyboard="false">
    <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Notizie</h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
                </button>
            </div>

            <div class="modal-body">
                <!-- RelatedBrowser ref="modal_related_browser" :show="showRelatedBrowser" :language="draftStore.draftDetail.language_id" :draft_uuid="draftStore.draftDetail.uuid" @hidebsmodal="onRelatedClose" @addrelated="onAddRelated" / -->
                <RelatedRemoteBrowser ref="modal_related_browser" :show="showRelatedBrowser" :language="draftStore.draftDetail.language_id" :remote_id="0" @hidebsmodal="onRelatedClose" @addrelated="onAddRelated" />
            </div>
        </div>
    </div>
</div>
    <!-- BEGIN: Modal Publish -->
    <PublishDraftVue id="modal_publish" :show="showPublish" @modal-action="draftModal" />
    <!-- BEGIN: Modal Publish -->

</template>

<style scoped>
.box-positions {
    column-count: 2;
    margin: 10px 0;
}

.box-check {
    margin: 10px 0;
}
.box-light {
    border: 1px solid #d7d5d5;
    border-radius: 15px;
    padding: 0 15px;
    background-color: white;
}
</style>


<script setup>

import { useRouter } from 'vue-router';
import { watch, ref, computed, onMounted, onUnmounted, nextTick } from 'vue'
import { useDraftStore, useNewsPaperStore, useAuthStore } from '@/stores';
import { storeToRefs } from 'pinia';
import { useNotification } from "@kyvg/vue3-notification";
import { useLoading } from '@/loader';
import DamImageBrowser from '../../components/newspaper/DamImageBrowser.vue';
import RelatedBrowser from '@/components/newspaper/RelatedBrowser.vue';
import RelatedDraft from '@/components/newspaper/RelatedDraft.vue';
import DamImages from '../../components/newspaper/DamImages.vue';
import DamFiles from '../../components/newspaper/DamFiles.vue';
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import PublishDraftVue from '../../components/newspaper/PublishDraft.vue';

import { ClassicEditor } from '@ckeditor/ckeditor5-editor-classic';
import enabledConfig from '@/ckeditor5/config/enabled';
import RelatedRemoteBrowser from '@/components/newspaper/RelatedRemoteBrowser.vue';
import { formatDistanceToNow , addMilliseconds } from 'date-fns'
import { it } from 'date-fns/locale'


import { Cropper } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';

const router = useRouter();
const draftStore = useDraftStore();
const newsPaperStore = useNewsPaperStore();
const authStore = useAuthStore(); 
const { isDraftLoading, draftList, draftDetail, draftDetailImages } = storeToRefs(draftStore);
const { selectedNewspaper } = storeToRefs(newsPaperStore);
const { notify } = useNotification();
const loader = useLoading();

const draftEditor = ref(null);
const imageEditor = ref(null);
const imgToEdit = ref(null);
const damEditor = ref(null);
const damEditorModal = ref(null);

const damBrowser = ref(null);
let modalDamBrowser;

const relatedBrowser = ref(null);
let modalRelatedBrowser;

const onDamBrowse = (show, context = "attach") => {
  modalDamBrowser.show();
  showDamBrowser.value = true;
  damContext.value = context;
};

const onDamEdit = (media) => {
    openEditor(media, 'inline');
};


let editorConfig = enabledConfig;
editorConfig['dam-image']['click'] = onDamBrowse;
editorConfig['dam-edit']['click'] = onDamEdit;
editorConfig.ServerId = router.currentRoute.value.params.newspaperid.split("-")[0];
editorConfig.NewspaperId = router.currentRoute.value.params.newspaperid;
editorConfig.newsPaperStore = useNewsPaperStore();

// BEGIN: CKEditor5
const editor = ClassicEditor;
const editorData =  ref('');
const draftEditorConfig = editorConfig;
// END: CKEditor5
const showDamBrowser = ref(false);
const showRelatedBrowser = ref(false);
const showPublish = ref(false);
const dateFormat = "yyyy-MM-dd hh:mm";
const textInputOptions = {
    format: "dd/MM/yyyy HH:mm"
};
const damContext = ref("attach");
const visibleVideoInsert = ref(false);
const editorContent = ref('');
const enabledDate = ref(true);
let updateInterval;
let autoSaveTimeout;
const autoSaveDeltaCounter = ref(0);


let imageEditorData = {
    mode: null,
    media: null,
    index: null
};


const rotate = (angle) => {
    imageEditor.value.rotate(angle);
    nextTick( () => {
        imageEditor.value.transitionsActive = false;
        imageEditor.value.setCoordinates({
            top: 0,
            left: 0,
            height: imageEditor.value.imageSize.height,
            width: imageEditor.value.imageSize.width
        });
    });
}

const flip = (x, y) => {
    const { image } = imageEditor.value.getResult();
    if (image.transforms.rotate % 180 !== 0) {
        imageEditor.value.flip(!x, !y);
    } else {
        imageEditor.value.flip(x, y);
    }
}


const defaultSize = ({ imageSize, visibleArea }) => {
    return {
        width: (imageSize || visibleArea).width,
        height: (imageSize || visibleArea).height,
    };
}

const closeImageEditor = () => {
    imageEditorData.media = null;
    imageEditorData.mode = null;
    imageEditorData.index = null;
    damEditorModal.value.hide();
}

const saveImage = () => {
    const { canvas } = imageEditor.value.getResult();

    if(imageEditorData.mode == 'inline') {
        canvas.toBlob( (blob) => {
            let url = new URL(imageEditorData.media.getAttribute("src"), window.document.location.origin);
            const fileName = url.pathname.split('\\').pop().split('/').pop();
            let params = url.searchParams;
            let file = new File([blob], decodeURI(fileName));
            draftStore.uploadImage(file, true).then( (response) => {
                let ckver = Number.parseInt(params.get("ckver"));
                if(isNaN(ckver)) {
                    ckver = 1;
                }
                draftEditor.value.instance.execute( 'replaceImageSource', { source: response.default + "?ckver=" + (ckver+1) } )
                imageEditorData.media = null;
                imageEditorData.mode = null;
            })
        })
    } else if(imageEditorData.mode == 'attachment') {
        if(imageEditorData.media.path) {
            canvas.toBlob( (blob) => {
                let url = new URL('/draft-images/' + imageEditorData.media.path, window.document.location.origin);
                const fileName = url.pathname.split('\\').pop().split('/').pop();
                let params = url.searchParams;
                let file = new File([blob], decodeURI(fileName));
                draftStore.uploadImage(file, true).then( (response) => {
                    let ckver = Number.parseInt(params.get("ckver"));
                    if(isNaN(ckver)) {
                        ckver = 1;
                    }
                    imageEditorData.media.path = response.default.replace('/draft-images/', '') + "?ckver=" + (ckver+1)
                    imageEditorData.media = null;
                    imageEditorData.mode = null;
                })
            });

        } else {
            draftStore.draftDetailImages.images[imageEditorData.index].url = canvas.toDataURL();
            imageEditorData.index = null;
            imageEditorData.mode = null;
            imageEditorData.media = null; 
        }

    } else {
        console.warn("Save image editor mode not supported:", imageEditorData.mode);
    }
    damEditorModal.value.hide();
}

onMounted(async () => {
    damEditorModal.value = new bootstrap.Modal(damEditor.value);
    damEditorModal.value._config.keyboard = false;
    $(damEditorModal.value._element).on('hide.bs.modal', onDamEditorClosing);

    modalDamBrowser = new bootstrap.Modal(damBrowser.value);
    modalDamBrowser._config.keyboard = false;
    $(modalDamBrowser._element).on('hide.bs.modal', onDamClosing);

    modalRelatedBrowser = new bootstrap.Modal(relatedBrowser.value);
    modalRelatedBrowser._config.keyboard = false;
    $(modalRelatedBrowser._element).on('hide.bs.modal', onRelatedClosing);

    await initNewsDetail();
    if(draftStore.draftDetail.starttime != null) {
        enabledDate.value = false;
    }
    enableAutoSave();
});

onUnmounted(() => {
    disableAutoSave();
});

const onDamEditorClosing = () => {
    imgToEdit.value = null;
}

const openEditor = (media, mode) => {
    
    imageEditorData.mode = mode;

    console.log("Media edit", media);
    if(mode == 'inline') {
        let src = media.getAttribute("src");
        if(src && src.startsWith("/draft-images/")) {
            imgToEdit.value = src;
            imageEditorData.media = media;
        } else {
            console.log("Immagine remota inline non supportata", media);
        }
    } else if(mode == 'attachment') {
        if(media.is_remote) {
            // From remote
            console.warn("Immagine remota allegata non supportata", media);
        } else if(media.orig) {
            // From PC
            imgToEdit.value = media.url;
            imageEditorData.media = media;
            imageEditorData.index = draftStore.draftDetailImages.images.indexOf(media);
        } else if(media.path) {
            // From draft
            imgToEdit.value = "/draft-images/" + media.path;
            imageEditorData.media = media;
            imageEditorData.index = draftStore.draftDetailImages.images.indexOf(media);
        }
    } else {
        console.warn("Modalità non supportata", mode);
    }

    damEditorModal.value.show();
}

watch( () => draftStore.mustSaveDraft, (value) => {
    if(value) {
        draftStore.saveDraft().then( (res) => {
            draftStore.mustSaveDraft = false;
            draftStore.startUpload = true;
        });
    }
});

const autoSaveDelta = computed({
    get() {
        if(!isNaN(autoSaveDeltaCounter.value)){
            return formatDistanceToNow( addMilliseconds(new Date(), autoSaveDeltaCounter.value), { locale: it, includeSeconds: true})
        }
        return autoSaveDeltaCounter.value;
    },
    set(value) {
        autoSaveDeltaCounter.value = value;
    }
});

const disableAutoSave = () => {
    clearInterval(updateInterval);
    clearTimeout(autoSaveTimeout);
};

const enableAutoSave = () => {
    if(authStore.user.preferences.autoSaveDraft > 0) {
        autoSaveStartCounter(authStore.user.preferences.autoSaveDraft * 60 * 1000);
    }
};

const autoSaveUpdateCounter = () => {
    autoSaveDelta.value = autoSaveDeltaCounter.value - 1000;
};

const autoSaveStartCounter = (seconds, doSave = false) => {
    autoSaveDelta.value = seconds;
    clearInterval(updateInterval);
    updateInterval = setInterval(autoSaveUpdateCounter, 1000);
    if(doSave) {
        saveDraft();
    }
    autoSaveTimeout = setTimeout(autoSaveStartCounter, seconds, seconds, true);
};

const onStartTimeChange = (value) => {
    if(value) {
        draftStore.draftDetail.datetime = value;
        enabledDate.value = false;
    } else {
        enabledDate.value = true;
        draftStore.draftDetail.datetime = null;
    }
};

const isDefaultLangPublished = () => {
    if(draftStore.draftDetail.language_id == 0) {
        return true;
    }
    const defaultLang = availableLang().reduce((cum, curr) => {
        if(curr.lang_id == 0) {
            cum = curr;
        }
        return cum;
    });
    return defaultLang.published;
};

const initNewsDetail = async (draft_uuid) => {
    
    if(!draft_uuid && router.currentRoute.value.params.uuid) {
        draft_uuid = router.currentRoute.value.params.uuid;
    }
    if (draft_uuid) {
        try {
            loader.show();
            if(draftStore.draftDetail.uid != draft_uuid) {
                await draftStore.fetchDraftDetail(draft_uuid);
            }
            editorData.value = draftStore.draftDetail.bodytext;
            draftEditor.value.instance.draftId = draftStore.draftDetail.uuid;
            loader.hide();
        }
        catch (err) {
            notify({
                text: "Errore nel caricamento della notizia",
                type: "danger"
            });
            console.log("Error loading draft", err);
            router.push({ name: "draft-list-newspaper", params: { newspaperid: router.currentRoute.value.params.newspaperid } });
        }
    } else {
        draftStore.resetLocalStore();
    }

    if(router.currentRoute.value.name == "draft-transl-new") {
        const langInfo = draftStore.draftDetail.langInfo;
        draftStore.resetLocalStore();
        draftStore.draftDetail.langInfo = langInfo;
    }
};

const onLangSelected = async (option) => {
    if(option.draft_uuid) {
        // Open existing translation
        if(option.published) {
            router.push({ name: 'news-view', params: { newspaperid: router.currentRoute.value.params.newspaperid, uuid: option.draft_uuid } });
        } else {
            router.push({ name: "draft-edit", params: { newspaperid: router.currentRoute.value.params.newspaperid, uuid: option.draft_uuid } });
            await initNewsDetail(option.draft_uuid);
        }
    } else {
        // Make new translation
        router.push({ name: "draft-transl-new", params: { newspaperid: router.currentRoute.value.params.newspaperid, uuid: router.currentRoute.value.params.uuid, langid: option.uid } });
        const langInfo = draftStore.draftDetail.langInfo;
        draftStore.resetLocalStore();
        draftStore.draftDetail.langInfo = langInfo;
        draftStore.draftDetail.language_id = option.uid;
    }
};

const availableLang = () => {
    const langs = newsPaperStore.getNewspaperLangs(newsPaperStore.selectedNewspaper);
    const langArray = Object.values(langs);
    //console.log("Newspaper langs", langArray);
    // langArray: list of available languages on newspaper
    langArray.map((item, index) => {
        const lang = draftStore.draftDetail.langInfo?.find((element) => {
            return element.lang_id == item.uid
        });

        if( lang ) {
            item.suffix = "";
            item.draft_uuid = lang.draft_uuid;
            item.published = lang.published;
        } else {
            item.suffix = " [NEW]";
            item.published = false;
        }
    });
    return langArray;
};

const onEditorReady = (evt) => {
    draftEditor.value.instance.model.on('_afterChanges', evt => {
        FB.XFBML.parse();
    });
    draftEditor.value.instance.saveDraft = saveDraft;
    draftEditor.value.instance.uploadImage = draftStore.uploadImage;
};

const onSlideVideoClosing = (event) => {
    visibleVideoInsert.value = false;
};
        
const onAddImage = (image) => {
    draftEditor.value.instance.execute('insertImage', { source: image.publicUrl+ "/" + image.url + "?uid=" + image.remote_id });
    let lastRange = draftEditor.value.instance.ui.editor.model.document.selection.getLastRange();
    draftEditor.value.instance.model.change( writer => {
        writer.setSelection( writer.createPositionAfter(  draftEditor.value.instance.ui.editor.model.document.selection.getSelectedElement()) );
    } );

};

const onAddRelated = (draft) => {
  const found = draftStore.draftDetail.related.find((item) => {
    if (draft.uid) return item.uid == draft.uid;
    return item.uuid == draft.uuid;
  });

  if (!found) {
    draftStore.draftDetail.related.push(draft);
  }
};

const onDamClose = () => {
  modalDamBrowser.hide();
};

const onRelatedClose = () => {
  modalRelatedBrowser.hide();
};

const onDamClosing = () => {
  showDamBrowser.value = false;
};

const onRelatedClosing = () => {
  showRelatedBrowser.value = false;
};



const onRelatedBrowse = (show) => {
  modalRelatedBrowser.show();
  showRelatedBrowser.value = true;
};

const publishDraft = async () => {
  draftStore.draftDetail.bodytext = editorData.value;
  if (document.getElementsByName("draft-edit")[0].checkValidity()) {
    try {
      disableAutoSave();
      loader.show();
      await draftStore.saveDraft();
      showPublish.value = true;
    } catch (e) {
      notify({
        text: "Errore nel salvataggio della notizia",
        type: "danger"
      });
    } finally {
      loader.hide();
    }
  } else {
    document.getElementsByName("draft-edit")[0].reportValidity();
  }
};

const saveDraft = () => {
    return new Promise((resolve, reject) => {
        if (document.getElementsByName("draft-edit")[0].checkValidity()) {
            try {
                loader.show();
                draftStore.draftDetail.bodytext = editorData.value;
                draftStore.saveDraft().then( (response) => {
                    editorData.value = draftStore.draftDetail.bodytext;
                    notify({
                        text: "Notizia salvata",
                        type: "success"
                    });
                    resolve();
                }).catch((reason) => {
                    notify({
                        text: "Errore nel salvataggio della notizia",
                        type: "danger"
                    });
                }).finally(() => {
                    loader.hide();
                });
            } catch(e) {
                notify({
                    text: e.message,
                    type: "danger"
                });
                reject(e.message);
            }
        } else {
            document.getElementsByName("draft-edit")[0].reportValidity();
            reject("Form not valid");
        }
    });
};

const saveDraftAndClose = async () => {
    saveDraft().then(() => {
        draftStore.resetLocalStore();
        closeDraft();
    }).catch((reason) => {
        console.log("Error saving draft", reason);
    });
};

const closeDraft = () => {
    router.push({ name: "draft-list-newspaper", params: { newspaperid: router.currentRoute.value.params.newspaperid } });
};

const onModalClose = () => {
    draftStore.resetLocalStore();
};

const draftModal = (action) => {
    if (action == "close") {
        showPublish.value = false;
        enableAutoSave();
    } else if(action == "done") {
        showPublish.value = false;
        closeDraft();
    }
};
</script>